import * as React from "react"
import {styled} from '@material-ui/core/styles';
import {graphql} from "gatsby"
import LayoutTmpl from "../components/layout";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import {GatsbyImage} from "gatsby-plugin-image";
import Paper from "@material-ui/core/Paper";
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import Button from '@material-ui/core/Button';
import Carousel from "react-elastic-carousel";
import {useState} from "react";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpdIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import {Helmet} from "react-helmet";
import {isLoggedIn} from "../services/auth"
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from 'prop-types';
import axios from "axios";
import ReviewsSort from "../components/reviewsSort";
import Links from '@material-ui/core/Link';
import AdSense from 'react-adsense';
import Chip from '@material-ui/core/Chip';
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import DialogTitle from "@material-ui/core/DialogTitle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledImageBox = styled(Box)(({theme}) => ({
    width: 256,
}));
const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));
const StyledRatingDiv = styled('div')(({theme}) => ({
    padding: 0,
    margin: 0,
}));

const breakPoints = [
    {width: 150, itemsToShow: 2},
    {width: 550, itemsToShow: 3},
    {width: 768, itemsToShow: 4},
    {width: 1200, itemsToShow: 6}
];

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const ElementTemplate = ({data}) => {

    const row = data.pageElements
    const images = data.pageImages
    const [iconSelectSortUseful, setIconSelectSortUseful] = useState(true);
    const [iconSelectSortDate, setIconSelectSortDate] = useState(false);
    const [iconSelectSortRating, setIconSelectSortRating] = useState(false);
    const [reviews, setReviews] = useState(row.element.reviews);
    const [type, setType] = useState(1);
    const [loadingSort, setLoadingSort] = useState(false);
    const loadingSortCSS = {display: loadingSort ? "block" : "none"};
    const [order, setOrder] = useState(2);

    function getSortReviews(element, typeChange) {
        let orderNew;
        if (typeChange !== type) {
            setOrder(2);
            setType(typeChange);
            orderNew = 2;
        } else {
            if (order === 2) {
                setOrder(1);
                orderNew = 1;
            } else {
                setOrder(2);
                orderNew = 2;
            }
        }
        if (typeChange === 1) {
            setIconSelectSortUseful(true);
        } else {
            setIconSelectSortUseful(false);
        }
        if (typeChange === 2) {
            setIconSelectSortDate(true);
        } else {
            setIconSelectSortDate(false);
        }
        if (typeChange === 3) {
            setIconSelectSortRating(true);
        } else {
            setIconSelectSortRating(false);
        }
        setLoadingSort(true);
        axios({
            method: "POST",
            data: window.localStorage.getItem("gatsbyUser"),
            url: "https://api.ocenivay.com/api/reviews-sort.php?type=" + typeChange + "&element=" + element + "&order=" + orderNew,
            responseType: 'json',
        })
            .then(res => {
                setReviews(res.data);
                setLoadingSort(false);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    const [progress, setProgress] = React.useState(0);
    const [dataLoading, setData] = useState(false);
    const getDataFromURL = (element, file, name) => new Promise(async (resolve, reject) => {
        axios({
            method: "POST",
            data: window.localStorage.getItem("gatsbyUser"),
            url: 'https://api.ocenivay.com/api/download.php?file=' + file + "&element=" + element,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                setData({messageError: ""});
                setData({messageSuccess: "Загрузка файла началась..."});
                let progresss = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress(progresss);
            }
        }).then(response => {
            setTimeout(() => {
                setData({messageSuccess: ""})
                setProgress(0);
            }, 3000)
            // const headerval = response.headers['content-disposition'];
            // const filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => {
            error.message = 'Файл доступен только авторизованным пользователям. <br /><a href="https://ocenivay.com/app/login" class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary">Авторизуйтесь</a> или <a href="https://ocenivay.com/app/reg" class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary">зарегистрируйтесь</a>';
            setData({messageError: error.message});
        });
    })

    const [initRepresentative, setInitRepresentative] = useState(false);
    const [representativeAdd, setRepresentativeAdd] = useState([]);
    function openFormRepresentative() {
        setInitRepresentative(true);
    }
    function closeFormRepresentative() {
        setInitRepresentative(false);
        setRepresentativeAdd([]);
    }
    function handleRepresentativeChange(e) {
        const target = e !== undefined ? e.target : undefined;
        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            setRepresentativeAdd({
                ...representativeAdd, [name]: value
            });

        }
    }
    function addUserRepresentative() {
        let formData = new FormData();
        for (const [key, value] of Object.entries(representativeAdd)) {
            formData.append(key, value)
        }
        formData.append(
            "elementId", row.element.id,
        );
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/representative-add.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data.type === undefined) {
                    closeFormRepresentative();
                }
                setSnackbarMessage(res.data.severity, res.data.message)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }
    const handleClick = () => {
        setOpen(true)
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    }

    return (

        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{row.element.name}</title>
                <meta name="keywords"
                      content={(row.element.keywords === null ? row.element.name : row.element.keywords)}/>
                <meta name="robots" content="index, follow"/>
                <meta name="x-robots-tag" content="all"/>
                <link rel="canonical" href={"https://ocenivay.com" + row.element.url}/>
                <meta name="description" content={row.element.text_short}/>
                <meta name="image" content={images.images.element.original[0].src}/>
                <meta property="og:type" content="article"/>
                <meta property="og:site_name" content="Ocenivay.com"/>
                <meta property="og:title" content={row.element.name}/>
                <meta property="og:description" content={row.element.text_short}/>
                <meta property="og:url" content={"https://ocenivay.com" + row.element.url}/>
                <meta property="og:locale" content="ru_RU"/>
                <meta property="og:image" content={images.images.element.original[0].src}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={row.element.name}/>
                <meta name="twitter:description" content={row.element.text_short}/>
                <meta name="twitter:image" content={images.images.element.original[0].src}/>
                <meta name="twitter:url" content={"https://ocenivay.com" + row.element.url}/>
                <meta name="twitter:domain" content="ocenivay.com"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>


            <Dialog open={initRepresentative}
                    onClose={closeFormRepresentative}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><VerifiedUserIcon style={{
                    margin: "0px 10px -5px 0px",
                    color: "green"
                }}/>Запрос на представителя</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{
                        fontSize: "0.8rem"
                    }}>
                                                    <span
                                                        style={{
                                                            color: "#333333",
                                                        }}
                                                    >
                                                        Вы сможете официально отвечать на отзывы посетителй сайта.
                                                        Для верификации аккаунта, укажите официальный сайт и контактный
                                                        E-mail (должен быть размещен на указанном сайте) на который
                                                        будет
                                                        выслан код подтверждения.
                                                    </span><br/>
                        <Links
                            href="/feedback"
                            style={{
                                color: '#3f51b5',
                                textShadow: 'none',
                                backgroundImage: 'none',
                                fontSize: '0.7rem',
                            }}
                        >Остались вопросы?</Links>
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                margin="dense"
                                id="site"
                                defaultValue="https://"
                                name="site"
                                label="Сайт"
                                type="text"
                                fullWidth
                                onChange={handleRepresentativeChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                margin="dense"
                                id="email"
                                name="email"
                                label="E-mail"
                                type="email"
                                fullWidth
                                onChange={handleRepresentativeChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        margin: "20px 16px 10px 16px",
                    }}
                ><Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Grid>
                        <Button onClick={closeFormRepresentative}
                                variant="contained" size="small"
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    margin: "0 20px 0 0",
                                }}
                        >
                            Отменить
                        </Button>
                    </Grid>
                    <Grid>
                        <Button onClick={addUserRepresentative}
                                variant="contained" size="small" color="primary"
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                }}
                        >
                            Отправить запрос
                        </Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>


            <div>
                <div itemType="http://schema.org/Product" itemScope>
                    <meta itemProp="mpn" content={row.element.id}/>
                    <meta itemProp="name" content={row.element.name}/>
                    <link itemProp="image" href={images.images.element.original[0].src}/>
                    <meta itemProp="description" content={row.element.text_short}/>
                    <div itemProp="aggregateRating" itemType="http://schema.org/AggregateRating" itemScope>
                        <meta itemProp="reviewCount"
                              content={(row.element.reviews_count_number <= 0 ? 1 : row.element.reviews_count_number)}/>
                        <meta itemProp="ratingValue" content={(row.element.rating <= 0 ? 5 : row.element.rating)}/>
                    </div>
                    <div itemProp="review" itemType="http://schema.org/Review" itemScope>
                        <div itemProp="author" itemType="http://schema.org/Person" itemScope>
                            <meta itemProp="name" content={(typeof row.element.reviews[0] !== 'undefined'
                                    ? row.element.reviews[0].user.name : "Anonymous"
                            )}
                            />
                        </div>
                        <div itemProp="reviewRating" itemType="http://schema.org/Rating" itemScope>
                            <meta itemProp="ratingValue" content={(typeof row.element.reviews[0] !== 'undefined'
                                    ? row.element.reviews[0].rating.number : 5
                            )}
                            />
                            <meta itemProp="bestRating" content="5"/>
                        </div>

                    </div>
                </div>
            </div>

            <div itemType="http://schema.org/ImageObject" itemScope>
                <meta itemProp="name" content={row.element.name}/>
                <link itemProp="image" href={images.images.element.original[0].src}/>
                <meta itemProp="description" content={row.element.text_short}/>
            </div>

            <LayoutTmpl>

                <main>

                    <StyledContainer maxWidth="md">

                        <StyledPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column">
                                        <Grid item xs>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                <Grid>
                                                    <StyledRatingDiv>
                                                        <Rating name="half-rating-read"
                                                                style={{
                                                                    margin: '0 10px 0 0'
                                                                }}
                                                                value={parseFloat(row.element.rating)}
                                                                precision={0.5} readOnly/>
                                                    </StyledRatingDiv>
                                                </Grid>
                                                <Grid
                                                    style={{
                                                        padding: '1px 0 0 0'
                                                    }}
                                                >
                                                    <Typography variant="body2" component="div" color="textPrimary"
                                                                style={{
                                                                    fontSize: '0.8rem',
                                                                    margin: '3px 0 0 0'
                                                                }}
                                                    >
                                                        Рейтинг {
                                                        ((parseFloat(row.element.rating) < 3) &&
                                                            <Chip
                                                                size="small"
                                                                label={parseFloat(row.element.rating)}
                                                                style={{
                                                                    width: "2.7rem",
                                                                    height: "1rem",
                                                                    fontSize: '0.8rem',
                                                                    padding: '1px 0 0 0',
                                                                    margin: '-3px 0 0 0',
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                }}/>
                                                        )
                                                    }
                                                        {
                                                            ((parseFloat(row.element.rating) >= 3 && parseFloat(row.element.rating) < 4) &&
                                                                <Chip
                                                                    size="small"
                                                                    label={parseFloat(row.element.rating)}
                                                                    style={{
                                                                        width: "2.7rem",
                                                                        height: "1rem",
                                                                        fontSize: '0.8rem',
                                                                        padding: '1px 0 0 0',
                                                                        margin: '-3px 0 0 0',
                                                                        backgroundColor: '#F3DD10FF',
                                                                        color: 'black',
                                                                    }}/>
                                                            )
                                                        }
                                                        {
                                                            ((parseFloat(row.element.rating) >= 4) &&
                                                                <Chip
                                                                    size="small"
                                                                    label={parseFloat(row.element.rating)}
                                                                    style={{
                                                                        width: "2.7rem",
                                                                        height: "1rem",
                                                                        fontSize: '0.8rem',
                                                                        padding: '1px 0 0 0',
                                                                        margin: '-3px 0 0 0',
                                                                        backgroundColor: 'green',
                                                                        color: 'white',
                                                                    }}/>
                                                            )
                                                        } на
                                                        основе {row.element.reviews_count_number} {row.element.reviews_count_text}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs>
                                            <Typography gutterBottom component="h1" variant="h5"
                                                        color="textPrimary"
                                                        dangerouslySetInnerHTML={{__html: row.element.name}}/>
                                            <Typography variant="body2" gutterBottom
                                                        dangerouslySetInnerHTML={{__html: row.element.text}}/>
                                        </Grid>
                                        <Grid item xs><Typography variant="body2" component="p"
                                                                  color="textPrimary"
                                                                  style={{
                                                                      fontWeight: 'normal',
                                                                      fontSize: '0.7rem',
                                                                      margin: '10px 0px 20px 0px'
                                                                  }}

                                        >
                                            {
                                                (row.element.tags !== undefined ?
                                                        row.element.tags.map((tag, t) => {
                                                                return (
                                                                    <span key={t}>
                                                                        <Links href={tag.url}
                                                                               style={{
                                                                                   color: '#3f51b5',
                                                                                   textShadow: 'none',
                                                                                   backgroundImage: 'none',
                                                                                   fontSize: '0.7rem',
                                                                                   margin: '0px 10px 0px 0px'
                                                                               }}
                                                                        >#{tag.name}</Links>&nbsp;
                                                                    </span>
                                                                )
                                                            }
                                                        )
                                                        :
                                                        ""
                                                )
                                            }</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            {
                                                (
                                                    !row.element.representative ?
                                                        <Links
                                                            onClick={openFormRepresentative}
                                                            style={{
                                                                textShadow: 'none',
                                                                backgroundImage: 'none',
                                                                fontSize: '0.8rem',
                                                                fontWeight: "400",
                                                                textTransform: 'none',
                                                                margin: '0 0 -10px 0',
                                                                cursor: "pointer",
                                                                color: "#969696"
                                                            }}
                                                        >Вы представитель<LiveHelpOutlinedIcon style={{
                                                            margin: '0 0 -5px 1px',
                                                            fontSize: '1.2rem',
                                                        }}/></Links>
                                                        :
                                                        <Typography variant="body2" component="p"
                                                                    color="textPrimary"
                                                                    style={{
                                                                        fontWeight: 'normal',
                                                                        fontSize: '0.7rem',
                                                                        color: "#4caf50"
                                                                    }}

                                                        ><VerifiedUserIcon style={{
                                                            fontWeight: 'normal',
                                                            fontSize: '0.7rem',
                                                            margin: "0px 0px -2px 0px"
                                                        }} /> Представитель на сайте</Typography>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <StyledImageBox>
                                        {
                                            (images.localImages[0] !== 'undefined' && images.localImages[0] !== null
                                                    ?
                                                    <Gallery>
                                                        <Item
                                                            original={images.images.element.original[0].src}
                                                            thumbnail={images.images.element.thumbnail[0].src}
                                                            width={images.images.element.original[0].width}
                                                            height={images.images.element.original[0].height}
                                                        >
                                                            {({ref, open}) => (
                                                                <div
                                                                    onKeyPress={open.handleKeyPress}
                                                                    role="button"
                                                                    tabIndex="0"
                                                                    ref={ref} onClick={open}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    <GatsbyImage
                                                                        image={images.localImages[0].childImageSharp.gatsbyImageData}
                                                                        alt={row.element.name}/>
                                                                </div>
                                                            )}

                                                        </Item>

                                                    </Gallery>
                                                    : ""
                                            )
                                        }
                                    </StyledImageBox>
                                </Grid>

                                {
                                    (images.images.element.original.length > 1 && images.images.element.original[1] !== 'undefined' && images.images.element.original[1] !== null ?
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center">
                                                <Gallery>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        style={{
                                                            marginTop: '20px',
                                                        }}
                                                    >
                                                        <Carousel breakPoints={breakPoints} pagination={false}>
                                                            {images.images.element.thumbnail.map((photo, i) => (
                                                                <Item
                                                                    key={i}
                                                                    original={images.images.element.original[i].src}
                                                                    thumbnail={photo.src}
                                                                    width={images.images.element.original[i].width}
                                                                    height={images.images.element.original[i].height}
                                                                >
                                                                    {({ref, open}) => (
                                                                        <div
                                                                            onKeyPress={open.handleKeyPress}
                                                                            role="button"
                                                                            tabIndex="0"
                                                                            ref={ref} onClick={open}
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <img ref={ref}
                                                                                 src={photo.src}
                                                                                 title={row.element.name}
                                                                                 alt={row.element.name}
                                                                                 height={photo.height}
                                                                                 style={{
                                                                                     cursor: 'pointer'
                                                                                 }}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                </Item>
                                                            ))}

                                                        </Carousel>

                                                    </Grid>
                                                </Gallery>
                                            </Grid>
                                            : ""
                                    )
                                }


                            </Grid>

                            {
                                (row.element.files.length > 0 || row.element.links.length > 0 ?
                                        <Grid
                                            style={{
                                                paddingTop: '30px',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                        </Grid>
                                        : ""
                                )
                            }

                            <Grid>
                                <AdSense.Google
                                    client='ca-pub-3682640536453025'
                                    slot='9688855086'
                                    style={{display: 'block'}}
                                    format='auto'
                                    responsive='true'
                                />
                            </Grid>
                            <Grid>
                                {
                                    (typeof row.element.files !== 'undefined' && row.element.files.length > 0 ?
                                            <>
                                                <Typography variant="body2" component="p" align="left"
                                                            color="textPrimary"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                margin: '10px 0 5px 0'
                                                            }}
                                                >
                                                    Файлы
                                                </Typography>
                                                {(dataLoading.messageError !== undefined && dataLoading.messageError !== "" ?
                                                    <Alert severity="error" style={{
                                                        margin: "10px 0px 20px 0px"
                                                    }}><span
                                                        dangerouslySetInnerHTML={{__html: dataLoading.messageError}}/></Alert> : "")}
                                                {(dataLoading.messageSuccess !== undefined && dataLoading.messageSuccess !== "" ? <>
                                                    <Alert severity="success" style={{
                                                        margin: "10px 0px 20px 0px"
                                                    }}><span
                                                        dangerouslySetInnerHTML={{__html: dataLoading.messageSuccess}}/></Alert><LinearProgressWithLabel
                                                    value={progress} style={{
                                                    margin: "-30px 0px 20px 0px"
                                                }}/></> : "")}
                                                {
                                                    row.element.files.map((file, f) => {

                                                        return (
                                                            <Box key={f}
                                                                 style={{
                                                                     fontSize: '0.8rem',
                                                                     margin: '3px 0 0 0',
                                                                     cursor: 'pointer'
                                                                 }}
                                                            >
                                                                {isLoggedIn() ? "" : <LockOutlinedIcon style={{
                                                                    fontSize: '0.8rem',
                                                                    margin: '1px 3px -1px 0',
                                                                    color: "red",
                                                                    float: "left"
                                                                }}/>}
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontWeight: 'normal',
                                                                                fontSize: '0.7rem',
                                                                            }}
                                                                ><Links
                                                                    onClick={() => Promise.resolve(getDataFromURL(row.element.id, file.id, file.name))}><strong
                                                                    dangerouslySetInnerHTML={{__html: file.name}}/> ({file.size})</Links></Typography>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ""
                                    )
                                }
                                {
                                    (typeof row.element.links !== 'undefined' && row.element.links.length > 0 ?
                                            <>
                                                <Typography variant="body2" component="h3" align="left"
                                                            color="textPrimary"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                margin: '10px 0 5px 0'
                                                            }}
                                                >Ссылки по теме</Typography>
                                                {
                                                    row.element.links.map((link, l) => {

                                                        return (
                                                            <Box key={l}
                                                                 style={{
                                                                     fontSize: '0.8rem',
                                                                     margin: '3px 0 3px 0'
                                                                 }}
                                                            >
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontWeight: 'normal',
                                                                                fontSize: '0.8rem',
                                                                            }}
                                                                ><Links href={"/" + link.url + "/"}><strong
                                                                    dangerouslySetInnerHTML={{__html: link.name}}/></Links></Typography>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ""
                                    )
                                }

                                <Grid
                                    style={{
                                        paddingTop: '30px',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    <Divider/>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        marginTop: '15px',
                                    }}
                                >
                                    <Grid
                                    >
                                        {
                                            typeof row.element.prev !== undefined && row.element.prev.url !== undefined && row.element.prev.url !== null &&
                                            <Box
                                                style={{
                                                    fontSize: '0.7rem',
                                                }}
                                            >
                                                Назад:<br/>
                                                <Typography variant="body2" component="p"
                                                            color="textPrimary"
                                                            style={{
                                                                fontWeight: 'normal',
                                                                fontSize: '0.7rem',
                                                            }}
                                                ><Links href={row.element.prev.url}><strong
                                                    dangerouslySetInnerHTML={{__html: row.element.prev.name}}/></Links></Typography>
                                            </Box>
                                        }
                                    </Grid>
                                    <Grid
                                    >
                                        {
                                            typeof row.element.next !== undefined && row.element.next.url !== undefined && row.element.next.url !== null &&
                                            <Box
                                                style={{
                                                    fontSize: '0.7rem'
                                                }}
                                            >
                                                Вперед:<br/>
                                                <Typography variant="body2" component="p"
                                                            color="textPrimary"
                                                            style={{
                                                                fontWeight: 'normal',
                                                                fontSize: '0.7rem',
                                                            }}
                                                ><Links href={row.element.next.url}><strong
                                                    dangerouslySetInnerHTML={{__html: row.element.next.name}}/></Links></Typography>
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledPaper>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                margin: '25px 0px 0px 0px',
                            }}
                        >
                            <Grid>
                                <Button variant="contained"
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            fontSize: '0.8rem',
                                            fontWeight: "bold",
                                            textTransform: 'none',
                                            margin: '5px',
                                            backgroundColor: "#fc0",
                                            color: "black",
                                        }}
                                        color="secondary"
                                        component={Links}
                                        href={"/app/review?elementId=" + row.element.id}
                                >Оставить отзыв</Button>
                            </Grid>
                        </Grid>


                        <StyledPaper
                            style={{
                                margin: '20px 0 20px 0',
                            }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid>
                                            <Typography variant="body2" component="h5" color="textPrimary"
                                                        style={{
                                                            fontSize: '0.8rem',
                                                            margin: '5px'
                                                        }}
                                            >Сортировать отзывы:
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Button variant="outlined" size="small"
                                                    style={{
                                                        textShadow: 'none',
                                                        backgroundImage: 'none',
                                                        fontSize: '0.8rem',
                                                        fontWeight: 400,
                                                        textTransform: 'none',
                                                        margin: '5px'
                                                    }}
                                                    endIcon={(iconSelectSortUseful ? order === 1 ? <ArrowUpdIcon/> :
                                                        <ArrowDownwardIcon/> : "")}
                                                    onClick={() => Promise.resolve(getSortReviews(row.element.id, 1))}
                                            >По полезности</Button>
                                            <Button variant="outlined" size="small"
                                                    style={{
                                                        textShadow: 'none',
                                                        backgroundImage: 'none',
                                                        fontSize: '0.8rem',
                                                        fontWeight: 400,
                                                        textTransform: 'none',
                                                        margin: '5px'
                                                    }}
                                                    endIcon={(iconSelectSortDate ? order === 1 ? <ArrowUpdIcon/> :
                                                        <ArrowDownwardIcon/> : "")}
                                                    onClick={() => Promise.resolve(getSortReviews(row.element.id, 2))}
                                            >По дате</Button>
                                            <Button variant="outlined" size="small"
                                                    style={{
                                                        textShadow: 'none',
                                                        backgroundImage: 'none',
                                                        fontSize: '0.8rem',
                                                        fontWeight: 400,
                                                        textTransform: 'none',
                                                        margin: '5px'
                                                    }}
                                                    endIcon={(iconSelectSortRating ? order === 1 ? <ArrowUpdIcon/> :
                                                        <ArrowDownwardIcon/> : "")}
                                                    onClick={() => Promise.resolve(getSortReviews(row.element.id, 3))}
                                            >По оценке</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </StyledPaper>
                        <span style={loadingSortCSS}><LinearProgress
                            style={{
                                margin: '-24px 1px 24px 1px'
                            }}
                        /></span>

                        <ReviewsSort reviews={reviews} element={row.element} type={type} order={order}/>

                    </StyledContainer>

                </main>

            </LayoutTmpl>
        </>
    )
}
export default ElementTemplate

export const pageQuery = graphql`
query elementByUrl($url: String!, $element: String!) {
    site {
        siteMetadata {
            title
        }
    }
    pageElements(element: {url: {eq: $url}}) {
        element {
            id
            name
            rating
            text_short
            representative
            keywords
            links {
                url
                name
            }
            files {
                size
                name
                id
            }
            prev {
                url
                name
            }
            next {
                url
                name
            }
            reviews {
                date
                id
                rating {
                    number
                    text
                }
                text_comment
                representative
                text_minus
                text_plus
                user {
                    name
                    id
                    avatar
                }
                vote {
                    plus
                    minus
                }
                comments
                spam
                url
            }
            reviews_count_number
            reviews_count_text
            tags {
                url
                name
            }
            text
            url
        }
    }
    pageImages(element: {eq: $element}) {
        localImages {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    width: 537
                    height: 382
                )
            }
        }
        images {
            element {
                original {
                    width
                    src
                    height
                }
                preview {
                    src
                    size {
                        width
                        height
                    }
                }
                thumbnail {
                    width
                    src
                    height
                }
            }
        }
    }
}
`
